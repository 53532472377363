import {
    AppBar,
    Box, Button,
    IconButton, TextField,
    Toolbar,
} from "@mui/material"
import { Cancel, Delete, Done, Edit } from "@mui/icons-material"
import { useState, } from "react"
import useDidUpdateEffect from "../../../hooks/commonHooks"
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

export const CommonSwiperFormattingForm = (props) => {

    const [collapse, setCollapse] = useState(false)

    const [slidesPerView, setSlidesPerView] = useState(props.data.slidesPerView ? props.data.slidesPerView : "")
    const [spaceBetween, setSpaceBetween] = useState(props.data.spaceBetween ? props.data.spaceBetween : "")
    const [delay, setDelay] = useState(props.data.delay ? props.data.delay : "")
    const [margin, setMargin] = useState(props.data.margin ? props.data.margin : "")
    const [padding, setPadding] = useState(props.data.padding ? props.data.padding : "")
    const [borderTop, setBorderTop] = useState(props.data.borderTop ? props.data.borderTop : "")
    const [borderBottom, setBorderBottom] = useState(props.data.borderBottom ? props.data.borderBottom : "")
    const [borderLeft, setBorderLeft] = useState(props.data.borderLeft ? props.data.borderLeft : "")
    const [borderRight, setBorderRight] = useState(props.data.borderRight ? props.data.borderRight : "")
    const [borderTopLeftRadius, setBorderTopLeftRadius] = useState(props.data.borderTopLeftRadius ? props.data.borderTopLeftRadius : "0px")
    const [borderTopRightRadius, setBorderTopRightRadius] = useState(props.data.borderTopRightRadius ? props.data.borderTopRightRadius : "0px")
    const [borderBottomLeftRadius, setBorderBottomLeftRadius] = useState(props.data.borderBottomLeftRadius ? props.data.borderBottomLeftRadius : "0px")
    const [borderBottomRightRadius, setBorderBottomRightRadius] = useState(props.data.borderBottomRightRadius ? props.data.borderBottomRightRadius : "0px")
    const [width, setWidth] = useState(props.data.width ? props.data.width : "")

    const handleChangeSlidesPerView = (event) => {
        setSlidesPerView(event.target.value)
    }
    const handleChangeSpaceBetween = (event) => {
        setSpaceBetween(event.target.value)
    }
    const handleChangeDelay = (event) => {
        setDelay(event.target.value)
    }
    const handleChangeBorderTop = (event) => {
        setBorderTop(event.target.value)
    }
    const handleChangeBorderBottom = (event) => {
        setBorderBottom(event.target.value)
    }
    const handleChangeBorderLeft = (event) => {
        setBorderLeft(event.target.value)
    }
    const handleChangeBorderRight = (event) => {
        setBorderRight(event.target.value)
    }
    const handleChangeBorderTopLeftRadius = (event) => {
        setBorderTopLeftRadius(event.target.value)
    }
    const handleChangeBorderTopRightRadius = (event) => {
        setBorderTopRightRadius(event.target.value)
    }
    const handleChangeBorderBottomLeftRadius = (event) => {
        setBorderBottomLeftRadius(event.target.value)
    }
    const handleChangeBorderBottomRightRadius = (event) => {
        setBorderBottomRightRadius(event.target.value)
    }
    const handleChangeWidth = (event) => {
        setWidth(event.target.value)
    }
    const handleChangeMargin = (event) => {
        setMargin(event.target.value)
    }
    const handleChangePadding = (event) => {
        setPadding(event.target.value)
    }

    const handleOnModified = (isCancel) => {
        if (!isCancel) {
            const temp = {
                id: props.data.id,
                slidesPerView: slidesPerView,
                spaceBetween: spaceBetween,
                delay: delay,
                margin: margin,
                padding: padding,
                type: props.data.type,
                borderLeft: borderLeft,
                borderRight: borderRight,
                borderBottom: borderBottom,
                borderTop: borderTop,
                width: width,
                borderTopLeftRadius: borderTopLeftRadius,
                borderTopRightRadius: borderTopRightRadius,
                borderBottomLeftRadius: borderBottomLeftRadius,
                borderBottomRightRadius: borderBottomRightRadius,
            }
            props.handleOnFormSubmit(temp)
        }
        setCollapse(!collapse)
    }

    return (
        <Box>
            <Box
                sx={{ height: "240px", width: "100%" }}
            >
                <Swiper
                    slidesPerView={slidesPerView}
                    spaceBetween={spaceBetween}
                    centeredSlides={true}
                    freeMode={true}
                    loop={true}
                    autoplay={{
                        delay: Number(delay),
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="https://swiperjs.com/demos/images/nature-2.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="https://swiperjs.com/demos/images/nature-3.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="https://swiperjs.com/demos/images/nature-4.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="https://swiperjs.com/demos/images/nature-5.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="https://swiperjs.com/demos/images/nature-6.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="https://swiperjs.com/demos/images/nature-7.jpg" />
                    </SwiperSlide>
                </Swiper>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <IconButton
                    size="large"
                    color="red"
                    onClick={() => setCollapse(!collapse)}
                >
                    <Edit />
                </IconButton>
                <IconButton
                    size="large"
                    color="red"
                    onClick={props.handleOnDelete}
                >
                    <Delete />
                </IconButton>
            </Box>
            {collapse && (
                <AppBar position="fixed" color="inherit" sx={{
                    top: 'auto', bottom: 0, justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Toolbar>
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Slides per view"
                            id="slides-per-view-input"
                            value={slidesPerView}
                            onChange={handleChangeSlidesPerView}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Space Between"
                            id="space-between-input"
                            value={spaceBetween}
                            onChange={handleChangeSpaceBetween}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Delay"
                            id="delay-input"
                            value={delay}
                            onChange={handleChangeDelay}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="width"
                            id="width-input"
                            value={width}
                            onChange={handleChangeWidth}
                            size="small"
                            style={{ width: '100px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Top"
                            id="border-top-input"
                            value={borderTop}
                            onChange={handleChangeBorderTop}
                            size="small"
                            style={{ width: '100px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Bottom"
                            id="border-bottom-input"
                            value={borderBottom}
                            onChange={handleChangeBorderBottom}
                            size="small"
                            style={{ width: '100px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Left"
                            id="border-left-input"
                            value={borderLeft}
                            onChange={handleChangeBorderLeft}
                            size="small"
                            style={{ width: '100px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Right"
                            id="border-right-input"
                            value={borderRight}
                            onChange={handleChangeBorderRight}
                            size="small"
                            style={{ width: '100px' }}
                        />
                    </Toolbar>
                    <Toolbar>
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Top Left Radius"
                            id="border-top-right-radius-input"
                            value={borderTopLeftRadius}
                            onChange={handleChangeBorderTopLeftRadius}
                            size="small"
                            style={{ width: '190px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Top Right Radius"
                            id="border-top-right-radius-input"
                            value={borderTopRightRadius}
                            onChange={handleChangeBorderTopRightRadius}
                            size="small"
                            style={{ width: '190px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Bottom Left Radius"
                            id="border-bottom-left-radius-input"
                            value={borderBottomLeftRadius}
                            onChange={handleChangeBorderBottomLeftRadius}
                            size="small"
                            style={{ width: '190px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Bottom Right Radius"
                            id="border-bottom-right-radius-input"
                            value={borderBottomRightRadius}
                            onChange={handleChangeBorderBottomRightRadius}
                            size="small"
                            style={{ width: '190px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Margin"
                            id="margin-input"
                            value={margin}
                            onChange={handleChangeMargin}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Padding"
                            id="padding-input"
                            value={padding}
                            onChange={handleChangePadding}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <Button
                            sx={{ m: 1 }}
                            size="medium"
                            variant="outlined"
                            onClick={() => handleOnModified(false)}
                            endIcon={<Done />}
                        >Done</Button>
                        <Button
                            color={'warning'}
                            sx={{ m: 1 }}
                            size="medium"
                            variant="outlined"
                            onClick={() => handleOnModified(true)}
                            endIcon={<Cancel />}
                        >Cancel</Button>
                    </Toolbar>
                </AppBar>
            )}
        </Box>
    )
}