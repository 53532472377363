import MainTable from "../../../../ui/table/mainTable";
import PageLayout from "../../../../ui/layout/pageLayout";
import {Button, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {formatNumberToReadable} from "../../../../../utils/app-utils";
import jsPDF from "jspdf";
import "jspdf-autotable";


export const ExpenseTable = ({data, header}) => {

    const exportPDF = () => {
        const doc = new jsPDF();

        // Add title
        doc.setFontSize(16);
        doc.text(header, 105, 15, null, null, "center");

        // Define columns and rows for autoTable
        const columns = [
            { header: "Description", dataKey: "id" },
            { header: "Total", dataKey: "totalExpense" },
        ];
        const rows = data.map(item => ({
            id: item.id,
            totalExpense: formatNumberToReadable(item.totalExpense),
        }));

        // Use autoTable to generate the table with styling options
        doc.autoTable({
            columns: columns,
            title: "hello world",
            body: rows,
            startY: 20, // Adjust the starting Y position of the table
            theme: "grid",
            headStyles: {
                fillColor: [63, 81, 181], // MUI primary color for the header background
                textColor: 255,           // White text color
                fontStyle: 'bold',        // Bold font style
                halign: 'center'          // Center alignment for header text
            },
            bodyStyles: {
                textColor: 50,            // Dark text color for body rows
                halign: 'center'          // Center alignment for body text
            },
            alternateRowStyles: {
                fillColor: [240, 240, 240] // Light grey background for alternate rows
            },
            styles: {
                cellPadding: 8,           // Padding inside each cell
                fontSize: 10,             // Font size similar to MUI table
            },
        });

        doc.save("table.pdf");
    };

    const tableHeader = (<TableHead>
        <TableRow>
            <TableCell>Expense ID</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align="left">Total</TableCell>
        </TableRow>
    </TableHead>)

    const tableBody = (
        (data && data.length > 0) ? (<TableBody>
                {data.map((item, index) => (
                    <TableRow
                        key={index}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left">{item.id}</TableCell>
                        <TableCell align="right">{formatNumberToReadable(item.totalExpense)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        ) : null
    )

    return (
        <div style={{margin: '15px'}}>
            <PageLayout header={header}>
                <Button variant="contained" color="primary" onClick={exportPDF} style={{ marginTop: "20px" }}>
                    Export as PDF
                </Button>
                <MainTable
                    iscontentLoading={false}
                    tableHeader={tableHeader} tableBody={tableBody}
                />
            </PageLayout>
        </div>
    )
}