import {useState, useEffect} from "react";

import {
    Box,
    TextField,
    DialogContent,
    DialogContentText,
    DialogActions, FormControl, InputLabel, Select, MenuItem, FormHelperText, Switch, FormControlLabel, Button,
} from "@mui/material";
import {Add, Cancel, Create, Update,} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

import {
    createEvent,
    getEventById,
    updateEvent
} from "../../../client/events/call";
import {loadLocationsSuggestions} from "../../../client/location/call";
import {loadFoundationsSuggestions} from "../../../client/foundation/call";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import * as moment from "moment/moment";
import {toast} from "react-toastify";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CreateUpdatePageBodyForm from "../../ui/common/createUpdatePageBodyForm";
import MainModalDialog from "../../ui/modal/mainModal";


// create item category form
export default function CreateEventForm(props) {

    const [isLoading, setIsLoading] = useState(false);

    // create user inputs
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [titleUnicode, setTitleUnicode] = useState('');
    const [titleSinhala, setTitleSinhala] = useState('');
    const [startedDate, setStartedDate] = useState(null);
    const [endedDate, setEndedDate] = useState(null);
    const [webPageContents, setWebPageContents] = useState([]);
    const [location, setLocation] = useState({});
    const [foundation, setFoundation] = useState({});
    const [enableForWeb, setEnableForWeb] = useState(false);
    const [shortDescription, setShortDescription] = useState('');

    const [locationSuggestions, setLocationSuggestions] = useState(['']);
    const [foundationSuggestions, setFoundationSuggestions] = useState(['']);

    // modal related properties
    const [modalOpen, setModalOpen] = useState(false);
    const [modelToOpen, setModelToOpen] = useState('');
    const [modelHeader, setModelHeader] = useState('');

    useEffect(() => {
        loadLocationSuggestionsFunc()
        loadFoundationSuggestionsFunc()
        if (props.isUpdate) {
            setIsLoading(true)
            getEventById(
                result => {
                    setId(result.content.id)
                    setTitle(result.content.title)
                    setTitleUnicode(result.content.titleUnicode)
                    setTitleSinhala(result.content.titleSinhala)
                    setStartedDate(result.content.startedDate)
                    setEndedDate(result.content.endedDate)
                    setLocation(result.content.monastery)
                    setFoundation(result.content.foundation)
                    setEnableForWeb(result.content.enableForWeb)
                    setWebPageContents(result.content.webPageContents ? result.content.webPageContents : [])
                    setStatus(result.content.status)
                    setShortDescription(result.content.shortDescription)

                    setIsLoading(false)
                },
                props.eventId,
                error => {
                },
            );
        }
    }, [])

    const [status, setStatus] = useState('');

    function loadLocationSuggestionsFunc() {
        loadLocationsSuggestions(result => {
            setLocationSuggestions(result.content);
        });
    }

    function loadFoundationSuggestionsFunc() {
        loadFoundationsSuggestions(result => {
            setFoundationSuggestions(result.content);
        });
    }

    //--------------------------------------------------
    const handleChangeTitle = (event) => {
        setTitle(event.target.value);
    };

    const handleChangeTitleUnicode = (event) => {
        setTitleUnicode(event.target.value);
    };

    const handleChangeTitleSinhala = (event) => {
        setTitleSinhala(event.target.value);
    };

    const handleChangeShortDescription = (value) => {
        setShortDescription(value.target.value);
    };

    const handleChangeStartedDate = (value) => {
        let val = null
        if (value) {
            val = value.$d
            setStartedDate(moment(val).format('YYYY-MM-DD'));
        } else {
            setStartedDate(null)
        }
        validateStartedDate(val)
    };

    const handleChangeEndedDate = (value) => {
        let val = null
        if (value) {
            val = value.$d
            setEndedDate(moment(val).format('YYYY-MM-DD'));
        } else {
            setEndedDate(null)
        }
        validateEndedDate(val)
    };

    const handleChangeLocationId = (event) => {
        setLocation(locationSuggestions.find((mnstry) => mnstry.id === event.target.value));
    };
    const handleChangeFoundationId = (event) => {
        setFoundation(foundationSuggestions.find((fnd) => fnd.id === event.target.value));
    };

    const handleChangeEnableForWeb = () => {
        setEnableForWeb(!enableForWeb);
    };

    // validate user inputs
    const [titleValidity, setTitleValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateTitle = () => {
        let validity = {};
        if (!title || title === '') {
            validity.isValid = false;
            validity.errorMessage = "Title cannot be empty"
            setTitleValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setTitleValidity(validity)
            return true
        }
    };

    // validate user inputs
    const [titleUnicodeValidity, setTitleUnicodeValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateTitleUnicode = () => {
        let validity = {};
        if (!titleUnicode || titleUnicode === '') {
            validity.isValid = false;
            validity.errorMessage = "Title unicode cannot be empty"
            setTitleUnicodeValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setTitleUnicodeValidity(validity)
            return true
        }
    };

    const [startedDateValidity, setStartedDateValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateStartedDate = () => {
        let validity = {};
        if (!startedDate || startedDate === '') {
            validity.isValid = false;
            validity.errorMessage = "StartedDate cannot be empty"
            setStartedDateValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setStartedDateValidity(validity)
            return true
        }
    };

    const [endedDateValidity, setEndedDateValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateEndedDate = () => {
        let validity = {};
        if (!endedDate || endedDate === '') {
            validity.isValid = false;
            validity.errorMessage = "EndedDate cannot be empty"
            setEndedDateValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setEndedDateValidity(validity)
            return true
        }
    };

    const setInputsToDefaultOnFormSubmit = () => {
        setId('')
        setTitle('')
        setTitleUnicode('')
        setTitleSinhala('')
        setStartedDate(null)
        setEndedDate(null)
        setLocation({})
        setFoundation({})
        setStatus('')
        setShortDescription('')
    }

    const validateInputFields = () => {
        let formValidators = []
        let isFormValid = true
        if (!validateTitle()) {
            formValidators[0] = false
        }
        if (!validateTitleUnicode()) {
            formValidators[1] = false
        }/*
        if (!validateStartedDate()) {
            formValidators[1] = false
        }
        if (!validateEndedDate()) {
            formValidators[2] = false
        }*/

        formValidators.map((val) => {
            if (!val) {
                isFormValid = false
            }
        })
        return isFormValid;
    }
    const createEventFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let event = {
            title: title,
            titleUnicode: titleUnicode,
            titleSinhala: titleSinhala,
            startedDate: startedDate,
            endedDate: endedDate,
            monasteryId: location ? location.id : "",
            foundationId: foundation ? foundation.id : "",
            enableForWeb: enableForWeb,
            shortDescription: shortDescription,
        };

        createEvent(
            (result) => {
                toast(`Event: ${event.title} was created!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            event,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const updateEventFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        let event = {
            id: id,
            title: title,
            titleUnicode: titleUnicode,
            titleSinhala: titleSinhala,
            startedDate: startedDate,
            endedDate: endedDate,
            monasteryId: location ? location.id : "",
            foundationId: foundation ? foundation.id : "",
            status: status,
            enableForWeb: enableForWeb,
            shortDescription: shortDescription,
        };

        updateEvent(
            (result) => {
                toast(`Event: ${event.title} was updated!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                setIsLoading(false)
                setInputsToDefaultOnFormSubmit()
                props.createOrUpdatecallback(result.content);
            },
            event,
            err => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            }
        );
    }

    const handleModalOpen = (model) => {
        if (model === 'createUpdatePageBody') {
            setModelHeader("Create/Update Page Body")
            setModelToOpen(createUpdatePageBodyForm)
        }
        setModalOpen(true);
    }
    const handleModalClose = () => setModalOpen(false);

    const createUpdatePageBodyForm = (<CreateUpdatePageBodyForm
        containers={webPageContents}
        parentType={"EVENT"}
        isUpdate={false}
        handleClose={handleModalClose}
        parentId={id}
        createOrUpdatecallback={(bdyContents) => {
            handleModalClose()
        }}
    />);

    return (<Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, width: '27ch'},
            }}
            noValidate
            autoComplete="off"
        >
            <MainModalDialog
                open={modalOpen}
                handleClose={handleModalClose}
                header={modelHeader}
                body={modelToOpen}
                size={'large'}
            />
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                <div>
                    <TextField
                        required
                        id="title-input"
                        label="Title"
                        onChange={handleChangeTitle}
                        value={title}
                        error={!titleValidity.isValid}
                        onBlur={validateTitle}
                        helperText={(titleValidity.isValid) ? "Enter title here" : titleValidity.errorMessage}
                    />
                    <TextField
                        required
                        id="title-unicode-input"
                        label="Title Unicode"
                        onChange={handleChangeTitleUnicode}
                        value={titleUnicode}
                        error={!titleUnicodeValidity.isValid}
                        onBlur={validateTitleUnicode}
                        helperText={(titleUnicodeValidity.isValid) ? "Enter title unicode here" : titleUnicodeValidity.errorMessage}
                    />
                    <TextField
                        sx={{'& input': {fontFamily: 'FMSamantha'}}}
                        required
                        id="title-sinhala-input"
                        label="Title Sinhala"
                        onChange={handleChangeTitleSinhala}
                        value={titleSinhala}
                        helperText={"Enter title in sinhala here"}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            type='date'
                            id="started-date-input"
                            label="Started Date"
                            inputFormat="YYYY-MM-DD"
                            value={startedDate}
                            onChange={handleChangeStartedDate}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    helperText={(startedDateValidity.isValid) ? "Enter started date here" : startedDateValidity.errorMessage}
                                    error={!startedDateValidity.isValid}
                                    onBlur={validateStartedDate}
                                />
                            }

                        />
                    </LocalizationProvider>
                </div>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            type='date'
                            id="ended-date-input"
                            label="Ended Date"
                            inputFormat="YYYY-MM-DD"
                            value={endedDate}
                            onChange={handleChangeEndedDate}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    helperText={(endedDateValidity.isValid) ? "Enter ended date here" : endedDateValidity.errorMessage}
                                    error={!endedDateValidity.isValid}
                                    onBlur={validateEndedDate}
                                />
                            }

                        />
                    </LocalizationProvider>
                </div>
                <div>
                    <FormControl sx={{m: 1, minWidth: '27ch'}}>
                        <InputLabel id="location-label">Location</InputLabel>
                        <Select
                            labelId="location-label"
                            id="location-input"
                            defaultValue=""
                            value={location ? location.id ? location.id : '' : ""}
                            onChange={handleChangeLocationId}
                            autoWidth
                            label="Location"
                        >
                            <MenuItem key={"none"} value="">
                                <em>None</em>
                            </MenuItem>
                            {(locationSuggestions) && locationSuggestions.map((location, index) => (
                                <MenuItem key={index} value={location.id}>{location.nameUnicode}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{"Select a location"}</FormHelperText>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: '27ch'}}>
                        <InputLabel id="foundation-label">Foundation</InputLabel>
                        <Select
                            labelId="foundation-label"
                            id="foundation-input"
                            defaultValue=""
                            value={foundation ? foundation.id ? foundation.id : '' : ""}
                            onChange={handleChangeFoundationId}
                            autoWidth
                            label="Foundation"
                        >
                            <MenuItem key={"none"} value="">
                                <em>None</em>
                            </MenuItem>
                            {(foundationSuggestions) && foundationSuggestions.map((foundation, index) => (
                                <MenuItem key={index} value={foundation.id}>{foundation.nameUnicode}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{"Select a foundation"}</FormHelperText>
                    </FormControl>
                    <FormControlLabel sx={{m: 1, minWidth: '27ch', marginRight: 0}} control={
                        <Switch checked={enableForWeb}
                                onChange={handleChangeEnableForWeb}
                                aria-label={"Enable for web"}
                                defaultChecked color="primary"/>}
                                      label={"Enable for web"}
                    />
                    <TextField
                        multiline
                        id="short-description-input"
                        label="Short Description"
                        onChange={handleChangeShortDescription}
                        value={shortDescription}
                        helperText={"Enter short description here"}
                    />
                </div>
                <div>
                    <Button
                        sx={{marginRight: 2, marginBottom: 2, marginLeft: 2}}
                        size="medium"
                        variant="outlined"
                        onClick={() => {handleModalOpen("createUpdatePageBody")}}
                        loadingPosition="end"
                        endIcon={<Add/>}
                    >Add/Update Web Page</Button>
                </div>
            </DialogContent>
            <DialogActions>
                {props.isUpdate ? (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={updateEventFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Update/>}
                >Update</LoadingButton>) : (<LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={createEventFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Create/>}
                >Create</LoadingButton>)}
                <LoadingButton
                    color={'warning'}
                    size="medium"
                    variant="outlined"
                    onClick={props.handleClose}
                    loading={false}
                    loadingPosition="end"
                    disabled={false}
                    endIcon={<Cancel/>}
                >Cancel</LoadingButton>
            </DialogActions>
        </Box>
    )
}