import {
    AppBar,
    Box, Button,
    FormControl,
    IconButton, InputLabel, MenuItem, Select, TextField,
    Toolbar,
    Typography,
    useMediaQuery
} from "@mui/material"
import { Cancel, Delete, Done, Edit } from "@mui/icons-material"
import { useState, } from "react"
import { WrapImageUploadForm } from "./wrapImageUploadForm"
import { WrapVideoAddForm } from "./wrapVideoAddForm"
import { API_CONFIGS } from "../../../config";
import { FontOptionList } from "../common/fontOptionList"
import { MuiColorInput } from "mui-color-input"
import { deleteFile } from "../../../client/fileUpload"
import useDidUpdateEffect from "../../../hooks/commonHooks"
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

export const CommonTextFormattingForm = (props) => {

    const [collapse, setCollapse] = useState(false)

    const [text, setText] = useState(props.data.text ? props.data.text : "")
    const [color, setColor] = useState(props.data.color ? props.data.color : "")

    const [lineHeight, setLineHeight] = useState(props.data.lineHeight ? props.data.lineHeight : "")
    const [fontSize, setFontSize] = useState(props.data.fontSize ? props.data.fontSize : "")
    const [font, setFont] = useState(props.data.font ? props.data.font : "")
    const [letterSpacing, setLetterSpacing] = useState(props.data.letterSpacing ? props.data.letterSpacing : "")
    const [backgroundColor, setBackgroundColor] = useState(props.data.backgroundColor ? props.data.backgroundColor : "")
    const [flexDirection, setFlexDirection] = useState(props.data.flexDirection ? props.data.flexDirection : "")
    const [wordSpacing, setWordSpacing] = useState(props.data.wordSpacing ? props.data.wordSpacing : "")
    const [margin, setMargin] = useState(props.data.margin ? props.data.margin : "")
    const [padding, setPadding] = useState(props.data.padding ? props.data.padding : "")
    const [borderTop, setBorderTop] = useState(props.data.borderTop ? props.data.borderTop : "")
    const [borderBottom, setBorderBottom] = useState(props.data.borderBottom ? props.data.borderBottom : "")
    const [borderLeft, setBorderLeft] = useState(props.data.borderLeft ? props.data.borderLeft : "")
    const [borderRight, setBorderRight] = useState(props.data.borderRight ? props.data.borderRight : "")
    const [borderTopLeftRadius, setBorderTopLeftRadius] = useState(props.data.borderTopLeftRadius ? props.data.borderTopLeftRadius : "0px")
    const [borderTopRightRadius, setBorderTopRightRadius] = useState(props.data.borderTopRightRadius ? props.data.borderTopRightRadius : "0px")
    const [borderBottomLeftRadius, setBorderBottomLeftRadius] = useState(props.data.borderBottomLeftRadius ? props.data.borderBottomLeftRadius : "0px")
    const [borderBottomRightRadius, setBorderBottomRightRadius] = useState(props.data.borderBottomRightRadius ? props.data.borderBottomRightRadius : "0px")
    const [width, setWidth] = useState(props.data.width ? props.data.width : "")
    const [fontStyle, setFontStyle] = useState(props.data.fontStyle ? props.data.fontStyle : "")
    const [textAlign, setTextAlign] = useState(props.data.textAlign ? props.data.textAlign : "")
    const [wrapImage, setWrapImage] = useState(props.data.wrapImage ? props.data.wrapImage : {})
    const [wrapVideo, setWrapVideo] = useState(props.data.wrapVideo ? props.data.wrapVideo : {})

    const matches = useMediaQuery('(min-width:992px)');

    const handleChangeFontSize = (event) => {
        setFontSize(event.target.value)
    }
    const handleChangeFont = (event) => {
        setFont(event.target.value)
    }
    const handleChangeFontStyle = (event) => {
        setFontStyle(event.target.value)
    }
    const handleChangeLineHeight = (event) => {
        setLineHeight(event.target.value)
    }
    const handleChangeTextAlign = (event) => {
        setTextAlign(event.target.value)
    }
    const handleChangeFlexDirection = (event) => {
        setFlexDirection(event.target.value)
    }
    const handleChangeLetterSpacing = (event) => {
        setLetterSpacing(event.target.value)
    }
    const handleChangeWordSpacing = (event) => {
        setWordSpacing(event.target.value)
    }
    const handleChangeColor = (value) => {
        setColor(value)
    }
    const handleChangeBackgroundColor = (value) => {
        setBackgroundColor(value)
    }
    const handleChangeBorderTop = (event) => {
        setBorderTop(event.target.value)
    }
    const handleChangeBorderBottom = (event) => {
        setBorderBottom(event.target.value)
    }
    const handleChangeBorderLeft = (event) => {
        setBorderLeft(event.target.value)
    }
    const handleChangeBorderRight = (event) => {
        setBorderRight(event.target.value)
    }
    const handleChangeBorderTopLeftRadius = (event) => {
        setBorderTopLeftRadius(event.target.value)
    }
    const handleChangeBorderTopRightRadius = (event) => {
        setBorderTopRightRadius(event.target.value)
    }
    const handleChangeBorderBottomLeftRadius = (event) => {
        setBorderBottomLeftRadius(event.target.value)
    }
    const handleChangeBorderBottomRightRadius = (event) => {
        setBorderBottomRightRadius(event.target.value)
    }
    const handleChangeWidth = (event) => {
        setWidth(event.target.value)
    }
    const handleChangeMargin = (event) => {
        setMargin(event.target.value)
    }
    const handleChangePadding = (event) => {
        setPadding(event.target.value)
    }
    const handleChangeText = (event) => {
        setText(event.target.value)
    }
    const handleOnModifiedWrapImage = (data) => {
        setWrapImage(data)
    }
    const handleOnModifiedWrapVideo = (data) => {
        setWrapVideo(data)
    }
    const handleOnModified = (isCancel) => {
        if (!isCancel) {
            const temp = {
                id: props.data.id,
                text: text,
                fontSize: fontSize,
                color: color,
                lineHeight: lineHeight,
                font: font,
                textAlign: textAlign,
                flexDirection: flexDirection,
                letterSpacing: letterSpacing,
                wordSpacing: wordSpacing,
                backgroundColor: backgroundColor,
                margin: margin,
                padding: padding,
                fontStyle: fontStyle,
                wrapImage: wrapImage,
                wrapVideo: wrapVideo,
                type: props.data.type,
                borderLeft: borderLeft,
                borderRight: borderRight,
                borderBottom: borderBottom,
                borderTop: borderTop,
                width: width,
                borderTopLeftRadius: borderTopLeftRadius,
                borderTopRightRadius: borderTopRightRadius,
                borderBottomLeftRadius: borderBottomLeftRadius,
                borderBottomRightRadius: borderBottomRightRadius,
            }
            props.handleOnFormSubmit(temp)
        }
        setCollapse(!collapse)
    }

    useDidUpdateEffect(() => {
        const temp = {
            id: props.data.id,
            text: text,
            fontSize: fontSize,
            color: color,
            lineHeight: lineHeight,
            font: font,
            textAlign: textAlign,
            flexDirection: flexDirection,
            letterSpacing: letterSpacing,
            wordSpacing: wordSpacing,
            backgroundColor: backgroundColor,
            margin: margin,
            padding: padding,
            fontStyle: fontStyle,
            wrapImage: wrapImage,
            wrapVideo: wrapVideo,
            type: props.data.type,
            borderLeft: borderLeft,
            borderRight: borderRight,
            borderBottom: borderBottom,
            borderTop: borderTop,
            width: width,
            borderTopLeftRadius: borderTopLeftRadius,
            borderTopRightRadius: borderTopRightRadius,
            borderBottomLeftRadius: borderBottomLeftRadius,
            borderBottomRightRadius: borderBottomRightRadius,
        }
        props.handleOnFormSubmit(temp)
    }, [wrapImage, wrapVideo])

    const deleteImage = (path) => {
        deleteFile(() => {
            setWrapImage({});
        },
            path,
            (error) => {
                console.log("file wasn't deleted")
            }
        )
        setWrapImage({});
    }

    const deleteVideo = (path) => {
        setWrapVideo({});
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: `${matches ? "row" : "column"}${flexDirection}`, marginTop: '1rem' }}>
                {!collapse && (
                    <Typography
                        sx={{
                            fontSize: fontSize,
                            fontFamily: font,
                            fontStyle: fontStyle,
                            lineHeight: lineHeight,
                            textAlign: textAlign,
                            letterSpacing: letterSpacing,
                            wordSpacing: wordSpacing,
                            color: color,
                            backgroundColor: backgroundColor,
                            margin: margin,
                            padding: padding,
                            borderLeft: borderLeft,
                            borderRight: borderRight,
                            borderBottom: borderBottom,
                            borderTop: borderTop,
                            width: width,
                            borderTopLeftRadius: borderTopLeftRadius,
                            borderTopRightRadius: borderTopRightRadius,
                            borderBottomLeftRadius: borderBottomLeftRadius,
                            borderBottomRightRadius: borderBottomRightRadius,
                        }}
                    >
                        {text}
                    </Typography>
                )}
                {collapse && (
                    <textarea
                        style={{
                            fontSize: fontSize,
                            fontFamily: font,
                            fontStyle: fontStyle,
                            lineHeight: lineHeight,
                            textAlign: textAlign,
                            letterSpacing: letterSpacing,
                            wordSpacing: wordSpacing,
                            color: color,
                            backgroundColor: backgroundColor,
                            margin: margin,
                            padding: padding,
                            borderLeft: borderLeft,
                            borderRight: borderRight,
                            borderBottom: borderBottom,
                            borderTop: borderTop,
                            width: width,
                            borderTopLeftRadius: borderTopLeftRadius,
                            borderTopRightRadius: borderTopRightRadius,
                            borderBottomLeftRadius: borderBottomLeftRadius,
                            borderBottomRightRadius: borderBottomRightRadius,
                        }}
                        type="text"
                        multiple
                        onChange={handleChangeText}
                        value={text}
                    />
                )}
                {wrapImage && wrapImage.image && (
                    <>
                        <img

                            style={{
                                marginLeft: flexDirection === "" ? '1rem' : 0,
                                marginRight: flexDirection === "-reverse" ? "1rem" : 0,
                                marginBottom: matches ? 0 : '1rem',
                                width: matches ? wrapImage.size : "100%",
                                height: matches ? wrapImage.size : "100%",
                                shapeOutside: `${API_CONFIGS.fileBaseUrl}${wrapImage.image}`,
                                borderLeft: wrapImage.borderLeft,
                                borderRight: wrapImage.borderRight,
                                borderTop: wrapImage.borderTop,
                                borderBottom: wrapImage.borderBottom,
                                borderTopLeftRadius: wrapImage.borderTopLeftRadius,
                                borderTopRightRadius: wrapImage.borderTopRightRadius,
                                borderBottomLeftRadius: wrapImage.borderBottomLeftRadius,
                                borderBottomRightRadius: wrapImage.borderBottomRightRadius,
                            }}
                            src={`${API_CONFIGS.fileBaseUrl}${wrapImage.image}`}
                        />
                        <IconButton
                            sx={{ margin: 'auto auto auto 3px' }}
                            onClick={() => deleteImage(wrapImage.image)}
                        >
                            <Delete />
                        </IconButton>
                    </>
                )}
                {wrapVideo && wrapVideo.url && (
                    <>
                        <div
                            style={{
                                marginLeft: flexDirection === "" ? '1rem' : 0,
                                marginRight: flexDirection === "-reverse" ? "1rem" : 0,
                                marginBottom: matches ? 0 : '1rem',
                            }}
                        >
                            <iframe
                                src={wrapVideo.url}
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowfullscreen
                                style={{
                                    width: 1920 * wrapVideo.size,
                                    height: 1080 * wrapVideo.size,
                                    borderLeft: wrapVideo.borderLeft,
                                    borderRight: wrapVideo.borderRight,
                                    borderTop: wrapVideo.borderTop,
                                    borderBottom: wrapVideo.borderBottom,
                                    borderTopLeftRadius: wrapVideo.borderTopLeftRadius,
                                    borderTopRightRadius: wrapVideo.borderTopRightRadius,
                                    borderBottomLeftRadius: wrapVideo.borderBottomLeftRadius,
                                    borderBottomRightRadius: wrapVideo.borderBottomRightRadius,
                                }}
                            >

                            </iframe>
                        </div>
                        <IconButton
                            sx={{ margin: 'auto auto auto 3px' }}
                            onClick={() => deleteVideo(wrapVideo)}
                        >
                            <Delete />
                        </IconButton>
                    </>
                )}
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <IconButton
                    size="large"
                    color="red"
                    onClick={() => setCollapse(!collapse)}
                >
                    <Edit />
                </IconButton>
                <IconButton
                    size="large"
                    color="red"
                    onClick={props.handleOnDelete}
                >
                    <Delete />
                </IconButton>
            </Box>
            {collapse && (
                <AppBar position="fixed" color="inherit" sx={{
                    top: 'auto', bottom: 0, justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Toolbar>
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="width"
                            id="width-input"
                            value={width}
                            onChange={handleChangeWidth}
                            size="small"
                            style={{ width: '100px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            id="font-size-input"
                            label="Size"
                            onChange={handleChangeFontSize}
                            value={fontSize}
                            style={{ width: '80px' }}
                            size="small"
                        />
                        <FontOptionList
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            id="font-size-input"
                            label="Font"
                            value={font}
                            onChangeValue={handleChangeFont}
                            style={{ width: '80px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Style"
                            id="font-style-input"
                            value={fontStyle}
                            onChange={handleChangeFontStyle}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Height"
                            id="line-height-input"
                            value={lineHeight}
                            onChange={handleChangeLineHeight}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <FormControl sx={{ m: 1, minWidth: '10ch', marginRight: 0 }}>
                            <InputLabel id="align-label"> Text align</InputLabel>
                            <Select
                                labelId="align-label"
                                id="align-input"
                                defaultValue=""
                                onChange={handleChangeTextAlign}
                                value={textAlign ? textAlign : ""}
                                autoWidth
                                label="Align"
                                size="small"
                            >
                                <MenuItem value={'center'}>Center </MenuItem>
                                <MenuItem value={'left'}>Left</MenuItem>
                                <MenuItem value={'right'}>Right</MenuItem>
                                <MenuItem value={'justify'}>Justified</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: '10ch', marginRight: 0 }}>
                            <InputLabel id="flex-direction-label"> Content Order</InputLabel>
                            <Select
                                labelId="font-label"
                                id="postion-input"
                                defaultValue=""
                                onChange={handleChangeFlexDirection}
                                value={flexDirection}
                                autoWidth
                                label="Content Order"
                                size="small"
                            >
                                <MenuItem value={''}>Left to Right </MenuItem>
                                <MenuItem value={'-reverse'}>Right to Left</MenuItem>
                            </Select>
                        </FormControl>
                    </Toolbar>
                    <Toolbar>
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Letter Spacing"
                            id="letter-spacing-input"
                            value={letterSpacing}
                            onChange={handleChangeLetterSpacing}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Word Spacing"
                            id="word-spacing-input"
                            value={wordSpacing}
                            onChange={handleChangeWordSpacing}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <MuiColorInput
                            format="hex"
                            value={backgroundColor}
                            onChange={handleChangeBackgroundColor}
                            size="small"
                            style={{ width: '100px' }}
                        />
                        <MuiColorInput
                            format="hex"
                            value={color}
                            onChange={handleChangeColor}
                            size="small"
                            style={{ width: '100px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Top"
                            id="border-top-input"
                            value={borderTop}
                            onChange={handleChangeBorderTop}
                            size="small"
                            style={{ width: '100px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Bottom"
                            id="border-bottom-input"
                            value={borderBottom}
                            onChange={handleChangeBorderBottom}
                            size="small"
                            style={{ width: '100px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Left"
                            id="border-left-input"
                            value={borderLeft}
                            onChange={handleChangeBorderLeft}
                            size="small"
                            style={{ width: '100px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Right"
                            id="border-right-input"
                            value={borderRight}
                            onChange={handleChangeBorderRight}
                            size="small"
                            style={{ width: '100px' }}
                        />
                    </Toolbar>
                    <Toolbar>
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Top Left Radius"
                            id="border-top-right-radius-input"
                            value={borderTopLeftRadius}
                            onChange={handleChangeBorderTopLeftRadius}
                            size="small"
                            style={{ width: '190px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Top Right Radius"
                            id="border-top-right-radius-input"
                            value={borderTopRightRadius}
                            onChange={handleChangeBorderTopRightRadius}
                            size="small"
                            style={{ width: '190px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Bottom Left Radius"
                            id="border-bottom-left-radius-input"
                            value={borderBottomLeftRadius}
                            onChange={handleChangeBorderBottomLeftRadius}
                            size="small"
                            style={{ width: '190px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Bottom Right Radius"
                            id="border-bottom-right-radius-input"
                            value={borderBottomRightRadius}
                            onChange={handleChangeBorderBottomRightRadius}
                            size="small"
                            style={{ width: '190px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Margin"
                            id="margin-input"
                            value={margin}
                            onChange={handleChangeMargin}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Padding"
                            id="padding-input"
                            value={padding}
                            onChange={handleChangePadding}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <WrapImageUploadForm
                            containerId={props.containerId}
                            data={wrapImage}
                            handleOnChange={(data) => {
                                handleOnModifiedWrapImage(data)
                            }}
                        />
                        <WrapVideoAddForm
                            containerId={props.containerId}
                            data={wrapVideo}
                            handleOnChange={(data) => {
                                handleOnModifiedWrapVideo(data)
                            }}
                        />
                        <Button
                            sx={{ m: 1 }}
                            size="medium"
                            variant="outlined"
                            onClick={() => handleOnModified(false)}
                            endIcon={<Done />}
                        >Done</Button>
                        <Button
                            color={'warning'}
                            sx={{ m: 1 }}
                            size="medium"
                            variant="outlined"
                            onClick={() => handleOnModified(true)}
                            endIcon={<Cancel />}
                        >Cancel</Button>
                    </Toolbar>
                </AppBar>
            )}
        </Box>
    )
}