import {
    Box, Divider, IconButton,
} from "@mui/material";
import { commonStyles } from "../../pages/style/commonStyle";
import { useEffect, useState } from "react";
import { CommonTextFormattingForm } from "./commonTextFormattingForm";
import { CommonTextListFormattingForm } from "./commonTextListFormattingForm";
import { CommonImagesUploadForm } from "./commonImagesUploadForm";
import { updateContentWrapContainer, getContentWrapContainerById } from "../../../client/contentWrapContainers/call";
import { toast } from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { WrapContainerSettingsBar } from "./wrapContainerSettingsBar";
import { ArrowCircleDown, ArrowCircleUp } from "@mui/icons-material";
import { AddContentButtons } from "../buttons/addContentButtons";
import { buttonTemplates, cardTemplates, imageGridTemplates, swiperTemplates, textLitsTemplates, textTemplates } from "../../../utils/temp-configs/pageContentTemplates";
import { generateUUID } from "../../../utils/app-utils";
import { CommonButtonFormatting } from "./commonButtonFormatting";
import { CommonSwiperFormattingForm } from "./commonSwiperFormattingForm";


export const PageContentWrapContainer = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const classes = commonStyles()

    const [hasToBeSaved, setHasToBeSaved] = useState(false);

    const [id, setId] = useState(props.containerId);
    const [contents, setContents] = useState([])
    const [settings, setSettings] = useState({});

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            getContentWrapContainerById(
                result => {
                    setContents(result.content.contents)
                    setSettings(result.content.settings)
                    setIsLoading(false)
                },
                error => {
                },
                id,
            );
        }
    }, [])

    const handleOnModifiedContainer = (data) => {
        setSettings(data)
        setHasToBeSaved(true)
    }
    const handleOnDeleteContainer = () => {
        props.onDelete()
    }

    const handleOnModifiedContent = (data, index) => {
        let temp = [...contents]
        temp.splice(index, 1, data);
        setContents(temp)
        setHasToBeSaved(true)
    }
    const handleOnDeleteContent = (id) => {
        const updatedData = contents.filter(item => item.id !== id);
        setContents(updatedData)
        setHasToBeSaved(true)
    }

    const handleOnContainerSave = () => {
        const temp = {
            "id": props.containerId,
            "parentId": props.parentId,
            "parentType": props.parentType,
            "settings": settings,
            "contents": contents,
        }
        updateContentWrapContainer(
            (data) => {
                setHasToBeSaved(false)
                toast(`Container: ${temp.id} was created!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon />
                    });
                setIsLoading(false)
            },
            temp,
            (err) => {
                setIsLoading(false)
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon />
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon />
                        });
                }
            })
    }
    const handleOnMove = (direction) => {
        if ("UP" === direction) {
            props.onMove(props.containerIndex - 1)
        } else if ("DOWN" === direction) {
            props.onMove(props.containerIndex + 1)
        }
    }

    const handleOnContentOptionSelect = (type, name, index) => {
        let temp = [...contents]
        let content = {}
        if (contents.length === 0) {
            index = 0;
        } else {
            index = index + 1
        }

        var stylesConfig
        if (type === "text") {
            stylesConfig = textTemplates.find(obj => {
                return obj.name === name
            })
            const sampleText = {
                "id": generateUUID(),
                "type": "TEXT",
                "text": "mdidÈl fjí wvúh fj; Tnj idorfhka ms<s.ksuq",
            }
            content = { ...stylesConfig, ...sampleText }
        } else if (type === "list") {
            stylesConfig = textLitsTemplates.find(obj => {
                return obj.name === name
            })
            const sampleList = {
                "id": generateUUID(),
                "type": "TEXT_LIST",
                "icon": "",
                "items": ["l,Hd‚ fhda.dY%u ixia:dj", "l,Hd‚ fhda.dY%u ixia:dj",],
            }
            content = { ...stylesConfig, ...sampleList }
        } else if (type === "images") {
            stylesConfig = imageGridTemplates.find(obj => {
                return obj.name === name
            })
            const sampleImages = {
                "id": generateUUID(),
                "images": [],
                "type": "IMAGES",
            }
            content = { ...stylesConfig, ...sampleImages }
        } else if (type === "button") {
            stylesConfig = buttonTemplates.find(obj => {
                return obj.name === name
            })
            const sampleButton = {
                "id": generateUUID(),
                "type": "BUTTON",
                "title": "mdidÈl"
            }
            content = { ...stylesConfig, ...sampleButton }
        } else if (type === "swiper") {
            stylesConfig = swiperTemplates.find(obj => {
                return obj.name === name
            })
            const sampleSwiper = {
                "id": generateUUID(),
                "type": "SWIPER",
            }
            content = { ...stylesConfig, ...sampleSwiper }
        }
        temp.splice(index, 0, content);
        setContents(temp)
        setHasToBeSaved(true)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: settings.position,
            }}
            data-aos={settings.transition}
        >
            <Box sx={{
                width: settings.width,
                height: settings.height,
                margin: settings.margin,
                padding: settings.padding,
                border: settings.border,
                borderRadius: settings.borderRadius,
                boxShadow: settings.boxShadow,
                position: "relative",
                backgroundImage: settings.backgroundImage,
                backgroundColor: settings.backgroundColor,
                overflow: "visible",
            }}
            >
                {settings && (
                    <WrapContainerSettingsBar
                        handleOnSave={handleOnContainerSave}
                        hasToBeSaved={hasToBeSaved}
                        onDelete={handleOnDeleteContainer}
                        data={settings}
                        containerId={props.containerId}
                        handleOnFormSubmit={(data) => {
                            handleOnModifiedContainer(data)
                        }}
                    />
                )}
                <Box
                    sx={{
                        display: 'flow-root',
                        padding: '1rem',
                        zIndex: 3,
                        overflow: 'scroll',
                        maxHeight: '55vh',
                    }}
                >
                    {contents.map((item, index) => (
                        <Box key={index}>
                            {("TEXT" === item.type && (
                                <CommonTextFormattingForm
                                    containerId={props.containerId}
                                    data={item}
                                    handleOnFormSubmit={(data) => handleOnModifiedContent(data, index)}
                                    handleOnDelete={() => {
                                        handleOnDeleteContent(item.id)
                                    }}
                                    setHasToBeSaved={setHasToBeSaved}
                                />
                            ))}
                            {("IMAGES" === item.type && (
                                <CommonImagesUploadForm
                                    containerId={props.containerId}
                                    data={item}
                                    handleOnFormSubmit={(data) => handleOnModifiedContent(data, index)}
                                    handleOnDelete={() => {
                                        handleOnDeleteContent(item.id)
                                    }}
                                />
                            ))}
                            {("TEXT_LIST" === item.type && (
                                <CommonTextListFormattingForm
                                    containerId={props.containerId}
                                    data={item}
                                    handleOnFormSubmit={(data) => handleOnModifiedContent(data, index)}
                                    handleOnDelete={() => {
                                        handleOnDeleteContent(item.id)
                                    }}
                                />
                            ))}
                            {("BUTTON" === item.type && (
                                <CommonButtonFormatting
                                    containerId={props.containerId}
                                    data={item}
                                    handleOnFormSubmit={(data) => handleOnModifiedContent(data, index)}
                                    handleOnDelete={() => {
                                        handleOnDeleteContent(item.id)
                                    }}
                                />
                            ))}
                            {("SWIPER" === item.type && (
                                <CommonSwiperFormattingForm
                                    containerId={props.containerId}
                                    data={item}
                                    handleOnFormSubmit={(data) => handleOnModifiedContent(data, index)}
                                    handleOnDelete={() => {
                                        handleOnDeleteContent(item.id)
                                    }}
                                />
                            ))}
                            <Divider />
                            <Box sx={{ display: 'flex' }} marginTop={'0.1rem'} marginBottom={'1rem'}>
                                <AddContentButtons
                                    index={index}
                                    menuOptions={textTemplates}
                                    title={"Text"}
                                    onSelect={(name) => handleOnContentOptionSelect("text", name, index)}
                                />
                                <AddContentButtons
                                    index={index}
                                    menuOptions={textLitsTemplates}
                                    title={"List"}
                                    onSelect={(name) => handleOnContentOptionSelect("list", name, index)}
                                />
                                <AddContentButtons
                                    index={index}
                                    menuOptions={imageGridTemplates}
                                    title={"Images"}
                                    onSelect={(name) => handleOnContentOptionSelect("images", name, index)}
                                />
                                <AddContentButtons
                                    index={index}
                                    menuOptions={buttonTemplates}
                                    title={"Button"}
                                    onSelect={(name) => handleOnContentOptionSelect("button", name, index)}
                                />
                                <AddContentButtons
                                    index={index}
                                    menuOptions={swiperTemplates}
                                    title={"Swiper"}
                                    onSelect={(name) => handleOnContentOptionSelect("swiper", name, index)}
                                />
                            </Box>

                        </Box>
                    ))}
                    {contents.length === 0 && (
                        <></>
                    )}
                </Box>
            </Box>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "center",
                    margin: "auto 1rem auto 1rem",
                }}
            >
                <IconButton
                    disabled={props.isFirstElement}
                    onClick={() => handleOnMove("UP")}
                >
                    <ArrowCircleUp fontSize={'large'} />
                </IconButton>
                <IconButton
                    disabled={props.isLastElement}
                    onClick={() => handleOnMove("DOWN")}
                >
                    <ArrowCircleDown fontSize={'large'} />
                </IconButton>
            </div>
        </Box>
    );
}