
export const textTemplates = [
    {
        type: "TEXT",
        text: "mdidÈl fjí wvúh fj; Tnj idorfhka ms<s.ksuq",
        name: "Header 1",
        color: "#344039",
        lineHeight: "normal",
        fontSize: "4rem",
        font: "FMBasuru",
        textAlign: "center",
        flexDirection: "normal",
        letterSpacing: "normal",
        textIndent: "0px",
        wordSpacing: "normal",
        backgroundColor: "none",
        margin: "5px auto",
        padding: "0px",
        fontStyle: "normal",
        borderLeft: '0px',
        borderRight: '0px',
        borderBottom: '0px',
        borderTop: '0px',
        width: '100%',
        borderTopLeftRadius: '50px',
        borderTopRightRadius: '0px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
    },
    {
        type: "TEXT",
        text: "mdidÈl fjí wvúh fj; Tnj idorfhka ms<s.ksuq",
        name: "Header 2",
        color: "#344039",
        lineHeight: "normal",
        fontSize: "3rem",
        font: "FMBasuru",
        textAlign: "center",
        position: "left",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "none",
        borderRadius: "5px",
        margin: "5px auto",
        padding: "5px",
        fontStyle: "normal",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderBottom: "none",
        width: "100%",
    },
    {
        type: "TEXT",
        text: "wmmld f; ukqfiiiq fh ckd mdr.dñfkd - w:dhx b;rd mcd ;Srfïjd kqOdj;s",
        name: "Header 2 - Ghatha - Paali",
        color: "#344039",
        lineHeight: "normal",
        fontSize: "2.3rem",
        font: "FMBasuru",
        textAlign: "center",
        position: "left",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "none",
        borderRadius: "5px",
        margin: "5px auto",
        padding: "5px",
        fontStyle: "normal",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderBottom: "none",
        width: "100%",
    },
    {
        type: "TEXT",
        text: "mdidÈl fjí wvúh fj; Tnj idorfhka ms<s.ksuq",
        name: "Header 3",
        color: "#344039",
        lineHeight: "normal",
        fontSize: "2.2rem",
        font: "FMBasuru",
        textAlign: "center",
        position: "left",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "none",
        borderRadius: "5px",
        margin: "5px auto",
        padding: "5px",
        fontStyle: "normal",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderBottom: "none",
        width: "100%",
    },
    {
        type: "TEXT",
        text: "mdidÈl fjí wvúh fj; Tnj idorfhka ms<s.ksuq",
        name: "Header 4",
        color: "#344039",
        lineHeight: "normal",
        fontSize: "1.8rem",
        font: "FMBasuru",
        textAlign: "center",
        position: "left",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "5px auto",
        padding: "5px",
        fontStyle: "normal",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderBottom: "none",
        width: "100%",
    },
    {
        type: "TEXT",
        text: "mdidÈl fjí wvúh fj; Tnj idorfhka ms<s.ksuq",
        name: "Header 5",
        color: "#BFBA6B",
        lineHeight: "normal",
        fontSize: "1.4rem",
        font: "FMBasuru",
        textAlign: "center",
        position: "left",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "none",
        borderRadius: "5px",
        margin: "5px auto",
        padding: "5px",
        fontStyle: "normal",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderBottom: "none",
        width: "100%",
    },
    {
        type: "TEXT",
        text: "mdidÈl fjí wvúh fj; Tnj idorfhka ms<s.ksuq",
        name: "Header 6",
        color: "#BFBA6B",
        lineHeight: "normal",
        fontSize: "1rem",
        font: "FMBasuru",
        textAlign: "center",
        position: "left",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "none",
        borderRadius: "5px",
        margin: "5px auto",
        padding: "5px",
        fontStyle: "normal",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderBottom: "none",
        width: "100%",
    },
    {
        type: "TEXT",
        text: "mdidÈl fjí wvúh fj; Tnj idorfhka ms<s.ksuq",
        name: "Paragraph - left bordered - orange",
        color: "#444444",
        lineHeight: "2rem",
        fontSize: "1.4rem",
        font: "FMMalithi",
        textAlign: "justify",
        flexDirection: "normal",
        letterSpacing: "normal",
        textIndent: "50px",
        wordSpacing: "normal",
        backgroundColor: "#fff8e6",
        margin: "auto",
        padding: "50px",
        fontStyle: "normal",
        borderLeft: '10px solid orange',
        borderRight: '0px',
        borderBottom: '0px',
        borderTop: '0px',
        width: '100%',
        borderTopLeftRadius: '50px',
        borderTopRightRadius: '0px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '50px',
    },
    {
        type: "TEXT",
        text: `mdidÈl fikiqka moaO;sfha uQ,drïNh iksgqyka jkqfha woaú;Sh we,a,lkao wdrKH fiakdikh;a iu.h' Y%S ,xld rdu[a[ uyd 
                ksldfha Y%S l,Hd‚ fhda.d Y%u ixia:dfõ f.!rjkSh YdLdjla jk we,a,lkao wdrKHh" ud;r lelKÿr m%foaYfha ukrï jk rlaIs;hla 
                weiqre fldgf.k iqjfia jev jikq ,nhs' tla mila úYd, jejlska jg ù we;s fuu wdrKHh" wE; w;S;fha isgu nK Ndjkd lghq;= iy 
                O¾uhg we,aula olajk ieoeyej;=kaf.a buy;a f.!rjhg md;% ù we;'`,
        name: "Paragraph - Normal - No Image",
        color: "#605849",
        lineHeight: "2rem",
        fontSize: "1.4rem",
        font: "FMMalithi",
        textAlign: "justify",
        flexDirection: "",
        letterSpacing: "normal",
        textIndent: "50px",
        wordSpacing: "normal",
        backgroundColor: "none",
        margin: "auto",
        padding: "0px",
        fontStyle: "normal",
        borderLeft: '0px',
        borderRight: '0px',
        borderBottom: '0px',
        borderTop: '0px',
        width: '100%',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        wrapImage:{}
    },
    {
        type: "TEXT",
        text: `^iir ihqf¾& mrf;rg hkafkda ñksiqka w;frys ÿ¾,N fj;s ^iaj,am fofkls&" wkH jQ jeä fokd fuf;r wkqju ÿj;a`,
        name: "Paragraph - Ghatha - Meaning",
        color: "rgb(221 154 59)",
        lineHeight: "2rem",
        fontSize: "1.4rem",
        font: "FMMalithi",
        textAlign: "center",
        flexDirection: "",
        letterSpacing: "normal",
        textIndent: "50px",
        wordSpacing: "normal",
        backgroundColor: "none",
        margin: "auto",
        padding: "0px",
        fontStyle: "normal",
        borderLeft: '0px',
        borderRight: '0px',
        borderBottom: '0px',
        borderTop: '0px',
        width: '100%',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        wrapImage:{}
    },
    {
        type: "TEXT",
        text: `mdidÈl fikiqka moaO;sfha uQ,drïNh iksgqyka jkqfha woaú;Sh we,a,lkao wdrKH fiakdikh;a iu.h' Y%S ,xld rdu[a[ uyd 
                ksldfha Y%S l,Hd‚ fhda.d Y%u ixia:dfõ f.!rjkSh YdLdjla jk we,a,lkao wdrKHh" ud;r lelKÿr m%foaYfha ukrï jk rlaIs;hla 
                weiqre fldgf.k iq   jfia jev jikq ,nhs' tla mila úYd, jejlska jg ù we;s fuu wdrKHh" wE; w;S;fha isgu nK Ndjkd lghq;= iy 
                O¾uhg we,aula olajk ieoeyej;=kaf.a buy;a f.!rjhg md;% ù we;'`,
        name: "Paragraph - Normal",
        color: "#605849",
        lineHeight: "2rem",
        fontSize: "1.4rem",
        font: "FMMalithi",
        textAlign: "justify",
        flexDirection: "",
        letterSpacing: "normal",
        textIndent: "50px",
        wordSpacing: "normal",
        backgroundColor: "none",
        margin: "auto",
        padding: "0px",
        fontStyle: "normal",
        borderLeft: '0px',
        borderRight: '0px',
        borderBottom: '0px',
        borderTop: '0px',
        width: '100%',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        wrapImage:{
            image: 'images/f50ecce9-dfb9-4c2b-a86d-099ecf352531/62124a5c-55e3-43a7-b077-0065310e4673_image-symbol-vector.jpg',
            size: "50%",
            borderLeft: "none",
            borderRight: "10px solid #BDBFB0",
            borderBottom: "none",
            borderTop: "none",
            borderTopLeftRadius: "100px",
            borderTopRightRadius: "30px",
            borderBottomLeftRadius: "30px",
            borderBottomRightRadius: "100px"
        }
    },
    {
        type: "TEXT",
        text: "mdidÈl fjí wvúh fj; Tnj idorfhka ms<s.ksuq",
        name: "Paragraph - left bordered - green",
        color: "#444444",
        lineHeight: "2rem",
        fontSize: "1.4rem",
        font: "FMMalithi",
        textAlign: "justify",
        flexDirection: "normal",
        letterSpacing: "normal",
        textIndent: "50px",
        wordSpacing: "normal",
        backgroundColor: "#e1ffc9",
        margin: "auto",
        padding: "50px",
        fontStyle: "normal",
        borderLeft: '10px solid #03600c',
        borderRight: '0px',
        borderBottom: '0px',
        borderTop: '0px',
        width: '100%',
        borderTopLeftRadius: '50px',
        borderTopRightRadius: '0px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '50px',
    },
]

export const imageGridTemplates = [
    {
        name: "Grid 1",
        caption: {
            color: "grey",
            lineHeight: "normal",
            fontSize: "1rem",
            font: "FMMalithi",
            textAllignment: "center",
            position: "center",
            letterSpacing: "normal",
            text: "mdidÈl fikiqk"
        },
        backgroundColor: "white",
        borderRadius: "5px",
        gap: "5",
        padding: "5px",
        border: "1px solid black",
        boxShadow: "0px 2px 10px rgba(0, 62, 200, 0.5)",
        columns: "3",
        justifyContent: "center",
        alignItems: "center",
    },
]

export const textLitsTemplates = [
    {
        name: "List 1",
        color: "blacck",
        lineHeight: "normal",
        fontSize: "1rem",
        font: "FMMalithi",
        textAlign: "center",
        position: "center",
        letterSpacing: "normal",
        wordSpacing: "normal",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "5px",
        padding: "5px",
        border: "1px solid black",
        fontStyle: "normal",
    },
]

export const buttonTemplates = [
    {
        name: "Outlined 1",
        type: "BUTTON",
        fontFamily: "FMMalithi",
        color: "warning",
        position: "center",
        margin: "20px auto",
        variant: "outlined",
        size: "medium",
        href: "#",
        title: ";jÿrg;a lshùug''''"
    },
    {
        name: "Outlined 2",
        fontFamily: "FMMalithi",
        color: "warning",
        position: "center",
        margin: "20px auto",
        variant: "outlined",
        size: "medium",
        href: "#",
        title: ";jÿrg;a lshùug''''"
    },
]

export const cardTemplates = [
    {
        name: "Card 1",
        cards: [
            {
                media: {
                    component: "img",
                    height: "140"
                },
                header: {
                    color: "black",
                    fontSize: "1rem",
                    fontFamily: "FMMalithi",
                },
                description: {
                    color: "grey",
                    fontSize: "0.8rem",
                    fontFamily: "FMMalithi",
                },
            }
        ],
        maxWidth: "",
        margin: "5px",
        padding: "5px",
        position: "center",
        border: "1px solid black",
        boxShadow: "0px 2px 10px rgba(0, 62, 200, 0.5)",
    },
]

export const containerTemplates = [
    {
        name: "Common 1",
        width: "80%",
        height: "auto",
        margin: "5px",
        padding: "5px",
        border: "1px solid black",
        borderRadius: "5px",
        boxShadow: "0px 2px 10px rgba(0, 62, 200, 0.5)",
        position: "center",
        backgroundColor: "white",
        backgroundImage: "none",
        transition: "fade-up",
    },
]

export const swiperTemplates = [
    {
        name: "Common 1",
        slidesPerView: 3,
        spaceBetween: 30,
        delay: 2500,
        margin: "5px",
        padding: "5px",
        borderLeft: '10px solid #03600c',
        borderRight: '0px',
        borderBottom: '0px',
        borderTop: '0px',
        width: '100%',
        borderTopLeftRadius: '50px',
        borderTopRightRadius: '0px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '50px',
    },
]

export const pageContentContainersTemplate = [
    {
        type: "TEXT",
        name: "Common 1",
        settings: {
            width: "80%",
            height: "auto",
            margin: "auto",
            padding: "50px",
            border: "none",
            borderRadius: "15px",
            boxShadow: "none",
            position: "center",
            backgroundColor: "#fffdf6",
            backgroundImage: "none",
            transition: "fade-up",
        },
        contents: [
            textTemplates.find(obj => {
                return obj.name === "Header 5"
            }),
            textTemplates.find(obj => {
                return obj.name === "Header 2"
            }),
            textTemplates.find(obj => {
                return obj.name === "Paragraph - Normal - No Image"
            }),
            textTemplates.find(obj => {
                return obj.name === "Paragraph - Normal"
            }),
        ],
    },
    {
        type: "TEXT",
        name: "CTA 1",
        settings: {
            width: "80%",
            height: "auto",
            margin: "50px auto",
            padding: "50px",
            border: "none",
            borderRadius: "15px",
            boxShadow: "none",
            position: "center",
            backgroundColor: "#fffdf6",
            backgroundImage: "none",
            transition: "fade-up",
        },
        contents: [
            textTemplates.find(obj => {
                return obj.name === "Header 5"
            }),
            textTemplates.find(obj => {
                return obj.name === "Header 2"
            }),
            textTemplates.find(obj => {
                return obj.name === "Paragraph - Normal - No Image"
            }),
            buttonTemplates.find(obj => {
                return obj.name === "Outlined 1"
            }),
        ],
    },
    {
        type: "TEXT",
        name: "Ghatha Container 1",
        settings: {
            width: "80%",
            height: "auto",
            margin: "50px auto",
            padding: "50px",
            border: "none",
            borderRadius: "15px",
            boxShadow: "none",
            position: "center",
            backgroundColor: "#fffdf6",
            backgroundImage: "none",
            transition: "fade-up",
        },
        contents: [
            textTemplates.find(obj => {
                return obj.name === "Header 2 - Ghatha - Paali"
            }),
            textTemplates.find(obj => {
                return obj.name === "Paragraph - Ghatha - Meaning"
            }),
        ],
    },
    {
        type: "TEXT",
        name: "Ghatha Container 2",
        settings: {
            width: "80%",
            height: "auto",
            margin: "50px auto",
            padding: "50px",
            border: "none",
            borderRadius: "15px",
            boxShadow: "none",
            position: "center",
            backgroundColor: "linear-gradient(90deg, rgba(52,64,57,1) 0%, rgba(162,166,140,1) 35%, rgba(255,248,225,1) 100%)",
            backgroundImage: "none",
            transition: "fade-up",
        },
        contents: [
            textTemplates.find(obj => {
                return obj.name === "Header 2 - Ghatha - Paali"
            }),
            textTemplates.find(obj => {
                return obj.name === "Paragraph - Ghatha - Meaning"
            }),
        ],
    },
]